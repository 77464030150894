import(/* webpackMode: "eager" */ "/var/www/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"work_sans\"}");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/node_modules/bootstrap/dist/css/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/public/favicon-180x180.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/Components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/Components/Header.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/src/styles/overwrite.scss");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/src/styles/globals.scss");
