"use client"

import Image from "next/image";
import Link from "next/link";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '@/images/logo-header.png';
import { APP_NAME } from "@/constant";
import { useEffect, useState } from "react";
import { redirect, usePathname } from "next/navigation";
import { getSession, removeSession } from "@/session";
import { AuthData } from "@/types";
import { Button } from "react-bootstrap";

export default function Header() {
	const pathname = usePathname()
	const [currentUser, setCurrentUser] = useState<AuthData | null>(null);

	useEffect(() => {
		(async () => {
			const session = await getSession();
			// console.log({session})
			if (session) {
				setCurrentUser(session)
			}
		})()
	}, [pathname]);

	useEffect(() => {
		if (!currentUser) {
			if (['/my-reservations', '/profile', '/change-password'].includes(pathname)) {
				redirect('/login');
			}
		}
	}, [currentUser])

	const logout = async () => {
		await removeSession();
		setCurrentUser(null);
	}

	return (
		<header>
			<Navbar expand="lg" >
				<Container>
					<Link href="/" className="navbar-brand">
						<Image
							src={logo}
							width="150"
							height="61"
							className="d-inline-block align-top"
							alt={APP_NAME}
						/>
					</Link>

					<Navbar.Toggle aria-controls="basic-navbar-nav" />

					<Navbar.Collapse id="right-navbar-nav">
						<Nav className="ms-auto">
							<Link href="/" className={`nav-link ${pathname === '/' ? 'active' : ''}`}>Home</Link>
							<Link href="/all-rooms" className={`nav-link ${pathname === '/all-rooms' ? 'active' : ''}`}>Residences</Link>

							<NavDropdown title="Property Info" id="property-info-nav-dropdown"
								className={` ${['/property-information', '/on-site-amenities', '/services', '/location'].includes(pathname) ? 'active' : ''}`}>
								<Link href="/property-information" className="dropdown-item">Property Information</Link>
								<Link href="/on-site-amenities" className="dropdown-item">On-site Amenities</Link>
								<Link href="/services" className="dropdown-item">Services</Link>
								<Link href="/location" className="dropdown-item">Location</Link>
							</NavDropdown>

							<Link href="/availability-calendar" className={`nav-link ${pathname === '/availability-calendar' ? 'active' : ''}`}>Calendar</Link>

							<Link href="/photo-gallery" className={`nav-link ${pathname === '/photo-gallery' ? 'active' : ''}`}>Gallery</Link>
							<Link href="/faq" className={`nav-link ${pathname === '/faq' ? 'active' : ''}`}>FAQ</Link>
							<Link href="/contact-us" className={`nav-link ${pathname === '/contact-us' ? 'active' : ''}`}>Contact Us</Link>
							<div className="nav-link-divider"></div>
							{currentUser ? (
								<NavDropdown title={currentUser.name} id="property-info-nav-dropdown"
									className={` ${['/my-reservations', '/profile', '/change-password'].includes(pathname) ? 'active' : ''}`}>
									<Link href="/my-reservations" className="dropdown-item">My Reservations</Link>
									<Link href="/profile" className="dropdown-item">Profile</Link>
									<Link href="/change-password" className="dropdown-item">Change Password</Link>
									<Button type="button" className="dropdown-item" onClick={logout}>Logout</Button>
								</NavDropdown>
							) : (
								<Link href="/login" className={`nav-link ${pathname === '/login' ? 'active' : ''}`}>Login</Link>
							)}
						</Nav>
					</Navbar.Collapse>

				</Container>
			</Navbar>
		</header>
	);
}
