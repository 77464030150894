"use client"

import Image from "next/image";
import Link from "next/link";
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationPin, faPhone } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
	return (
		<footer>
			<div className="contact-widget">
				<Container>
					<h5>Contact</h5>
					<ul>
						<li className='contact-address'><FontAwesomeIcon icon={faLocationPin} /> 1035 Seaside Dr, Sarasota, FL 34242</li>
						<li className='contact-phone'><FontAwesomeIcon icon={faPhone} /> 941 925 1035</li>
						<li className='contact-email'><FontAwesomeIcon icon={faEnvelope} /> info@Ten35Seaside.com</li>
					</ul>
				</Container>
			</div>

			<div className="copyright-info">
			<Container>
				<p>{new Date().getFullYear()} Ten35SeaSide. All Rights Reserved</p>
			</Container>
			</div>
		</footer>
	);
}
